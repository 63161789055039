<template>
  <div class="home">
    <!-- banner -->
    <el-row :gutter="20">
      <el-col :span="24"
        ><Banner :options="banner_options">
          <el-row>
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="slot_content container">
                <p class="tips">
                  <span>智能</span>
                  连接美好生活
                </p>
                <!-- <p class="company">——普森智慧</p> -->
                <p class="border_p">
                  <span></span>
                </p>
                <p>普森智慧科技-值得信赖的合作伙伴，为您提供优质的服务</p>
                <div>
                  <button class="key_button" @click="$router.push('/join')">
                    立即咨询
                  </button>
                </div>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row> </Banner
      ></el-col>
    </el-row>
    <!-- 公司价值观 -->
    <el-row :gutter="20">
      <el-col :span="4"><div class="blank_box"></div></el-col>
      <el-col :span="16">
        <div class="top_content">
          <div flex column class="bg_top_content">
            <div></div>
            <div></div>
          </div>
          <div class="container" flex align around>
            <div
              v-for="(item, index) in title_list"
              :key="item.id"
              class="item"
            >
              <img
                :src="item.img"
                width="100%"
                height="100%"
                :style="index === 2 ? 'width:94px' : ''"
              />
              <p>{{ item.title }}</p>
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="4"><div class="blank_box"></div></el-col>
    </el-row>
    <!-- 解决方案 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="solution">
          <img src="../../assets/img/home_bg.png" class="home_bg">
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="container title" flex column between>
                <p>解决方案</p>
                <p></p>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="container solution_content" flex between align>
                <div flex column center align-start>
                  <p>智能安防</p>
                  <p>
                    邻云管家智慧安防解决方案，依托于物联网、云计算、大数据、人工智能等新一代信息技术，实现智能门禁、智能监控、统一监控管理，提供高效、可靠、集约的智能安防门禁管理解决方案。
                  </p>
                  <button
                    class="key_button solution_button"
                    @click="solutionPage('security')"
                  >
                    查看更多
                  </button>
                </div>
                <img
                  src="../../assets/img/组 1.png"
                  alt=""
                  width="100%"
                  height="100%"
                />
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="container solution_content" flex between align>
                <img
                  src="../../assets/img/物业.png"
                  alt=""
                  width="100%"
                  height="100%"
                />
                <div flex column center align-start>
                  <p>智慧物业</p>
                  <p>
                    结合邻云管家智慧社区大数据管理平台，充分利用人工智能、物联网、云计算、大数据等新一代信息技术，实现对社区车辆、人员、事件的全面、精准管理，逐步建立完善社区周边大数据，为物业、社区、街道办...
                  </p>
                  <button
                    class="key_button solution_button"
                    @click="solutionPage('really')"
                  >
                    查看更多
                  </button>
                  <!-- <el-button type="warning" plain>查看更多</el-button> -->
                </div>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="container solution_content" flex between align>
                <div flex column center align-start>
                  <p>智能停车</p>
                  <p>
                    通过物联网传感控制器、移动支付等技术，有效帮助地产、物业实现对市面不同品牌停车硬件的轻松管控，为运营方提供更全面、有效的数据支撑。
                  </p>
                  <button
                    class="key_button solution_button"
                    @click="solutionPage('park')"
                  >
                    查看更多
                  </button>
                </div>
                <img
                  src="../../assets/img/停车.png"
                  alt=""
                  width="100%"
                  height="100%"
                />
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 公司简介 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="about">
          <el-row :gutter="20">
            <el-col :span="24">
              <div class="about_content">
                <el-row :gutter="20">
                  <el-col :span="12">
                    <div class="left">
                      <img
                        src="../../assets/img/我们.png"
                        alt=""
                        width="100%"
                        height="100%"
                      />
                    </div>
                  </el-col>
                  <el-col :span="12">
                    <div class="right">
                      <i class="iconfont icon-icon-test"></i>
                      <p class="about_title">关于我们</p>
                      <p class="border_p"></p>
                      <p>
                        成都普森智慧科技有限公司创立于2020年，是一家集软件开发、系统集成、弱电工程服务为一体的物联网科技公司。
                      </p>
                      <p>
                        公司致力于向社会提供更加优质的物联网技术、产品及服务，推动产业发展；以专业精致、极速响应、合作共赢为发展理念，努力构建智慧城市上下游产业为一体的产业链，形成多元化、智能化、特色化的经营模式，打造领先的智慧城市综合服务一体化平台，成为智慧城市建设的推动者和实践者。
                      </p>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="about_bottom contianer" flex between align>
                <div
                  class="item"
                  v-for="(item, index) in about_list"
                  :key="index"
                >
                  <p>
                    <i
                      class="iconfont"
                      :class="item.icon"
                      :style="item.icon_style"
                    ></i>
                    <span>{{ item.name }}</span>
                  </p>
                  <p>{{ item.content }}</p>
                </div>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 合作伙伴 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <div class="join">
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="container title" flex column between>
                <p>合作伙伴</p>
                <p></p>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="4"><div class="blank_box"></div></el-col>
            <el-col :span="16">
              <div class="container join_content" flex between>
                <div class="item" flex column between align>
                  <img :src="logo_list[0].img" width="100%" height="100%" />
                  <img :src="logo_list[1].img" width="100%" height="100%" />
                  <img :src="logo_list[2].img" width="100%" height="100%" />
                </div>
                <div class="item" flex column between align>
                  <img :src="logo_list[3].img" width="100%" height="100%" />
                  <img :src="logo_list[4].img" width="100%" height="100%" />
                  <img :src="logo_list[5].img" width="100%" height="100%" />
                </div>
                <div class="item" flex column between align>
                  <img :src="logo_list[6].img" width="100%" height="100%" />
                  <img :src="logo_list[7].img" width="100%" height="100%" />
                  <img :src="logo_list[8].img" width="100%" height="100%" />
                </div>
                <div class="item" flex column between align>
                  <img :src="logo_list[9].img" width="100%" height="100%" />
                  <img :src="logo_list[10].img" width="100%" height="100%" />
                  <img :src="logo_list[11].img" width="100%" height="100%" />
                </div>
                <div class="item" flex column between align>
                  <img :src="logo_list[12].img" width="100%" height="100%" />
                  <img :src="logo_list[13].img" width="100%" height="100%" />
                  <img :src="logo_list[14].img" width="100%" height="100%" />
                </div>
              </div>
            </el-col>
            <el-col :span="4"><div class="blank_box"></div></el-col>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <!-- 联系方式 -->
    <el-row :gutter="20">
      <el-col :span="24">
        <Contact></Contact>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import Banner from "@/components/banner.vue";
import Contact from "@/components/link.vue";
export default {
  components: {
    Banner,
    Contact,
  },
  data() {
    return {
      banner_options: {
        bg_img: require("@/assets/img/首页banner.png"),
      },
      title_list: [
        {
          id: 1,
          img: require("@/assets/img/组 35.png"),
          title: "专业精致",
        },
        {
          id: 2,
          img: require("@/assets/img/组 37.png"),
          title: "急速响应",
        },
        {
          id: 3,
          img: require("@/assets/img/组 36.png"),
          title: "合作共赢",
        },
      ],
      about_list: [
        {
          icon: "icon-shiming",
          name: "企业使命",
          content: "用科技守护每一个家庭",
          icon_style: {
            color: "rgba(255, 163, 61, 1)",
          },
        },
        {
          icon: "icon-qiyehexinjiazhiguan",
          name: "企业价值观",
          content: "诚信,无私,责任心",
          icon_style: {
            color: "rgba(92, 193, 254, 1)",
          },
        },
        {
          icon: "icon-yuanjing",
          name: "企业愿景",
          content: "打造中国智慧社区一流品牌",
          icon_style: {
            color: "rgba(252, 144, 120, 1)",
          },
        },
      ],
      logo_list: [
        {
          img: require("@/assets/img/ajb_logo.png"),
        },
        {
          img: require("@/assets/img/aly_logo.png"),
        },
        {
          img: require("@/assets/img/bxn_logo.png"),
        },
        {
          img: require("@/assets/img/hkws_logo.png"),
        },
        {
          img: require("@/assets/img/jljt_logo.png"),
        },
        {
          img: require("@/assets/img/jtyh_logo.png"),
        },
        {
          img: require("@/assets/img/pawy_logo.png"),
        },
        {
          img: require("@/assets/img/huawei_logo.png"),
        },
        {
          img: require("@/assets/img/jlkj_logo.png"),
        },
        {
          img: require("@/assets/img/jxjt_logo.png"),
        },
        {
          img: require("@/assets/img/dlk_logo.png"),
        },
        {
          img: require("@/assets/img/sldc_logo.png"),
        },
        {
          img: require("@/assets/img/zhd_logo.png"),
        },
        {
          img: require("@/assets/img/zgyh_logo.png"),
        },
        {
          img: require("@/assets/img/ahua_logo.png"),
        },
      ],
    };
  },
  mounted() {
    // console.log(1)
    window.scrollTo(0, 0);
  },
  methods: {
    solutionPage($id) {
      this.$router.push({
        path: "/solution",
        query: { $id: $id },
      });
      sessionStorage.setItem("menu_id", 2);
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  min-width: 1200px;
}
.slot_content {
  > p {
    text-align: left;
    color: #fff;
    letter-spacing: 2px;
  }
  > p:last-of-type {
    font-size: 20px;
    padding-top: 20px;
  }
  .tips {
    font-size: 72px;
    > span {
      color: rgba(247, 137, 0, 1);
    }
  }
  .company {
    font-size: 48px;
  }
}
.top_content {
  height: 248px;
  //   background: #ebeff8;
  box-shadow: 0px 0px 16px 4px rgba(117, 156, 255, 0.1);
  opacity: 0.9;
  position: relative;
  z-index: 130;
  .container {
    height: 100%;
    .item {
      position: relative;
      top: 0;
      left: 0;
      z-index: 150;
      opacity: 0.8;
      > p {
        color: rgba(68, 68, 68, 1);
        font-weight: bold;
        font-size: 18px;
        padding-top: 20px;
      }
      > img {
        width: 80px;
        height: 80px;
      }
    }
  }

  .bg_top_content {
    width: 100%;
    height: 248px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 140;
    > div {
      width: 100%;
      height: 50%;
    }
    > div:nth-of-type(1) {
      background-color: rgba(235, 239, 248, 1);
    }
    > div:nth-of-type(2) {
      background-color: rgba(255, 255, 255, 1);
    }
  }
}
.solution {
  background-color: #fff;
  position: relative;
  top: -70px;
  .home_bg {
    position: absolute;
    top: 30%;
    left: 5%;
    width: 90%;
    height: 60%;
    opacity: .6;
    // z-index: ;
  }
}
.join {
  background-color: #fff;
  .join_content {
    .item {
      width: 19%;
      height: 100%;
      img {
        // box-shadow: 0px 0px 8px 2px rgba(117, 156, 255, 0.1);
        border-radius: 2px;
        transition: all 0.3s;
        width: 100%;
        height: 30%;
      }
      img:hover {
        transform: scale(1.2);
        box-shadow: 0px 0px 16px 4px rgba(117, 156, 255, 0.1);
      }
    }
  }
}
.solution_content {
  > div {
    width: 45%;
    > p {
      width: 100%;
      text-align: left;
      line-height: 2;
    }
    > p:nth-of-type(1) {
      font-size: 28px;
      font-family: Source Han Sans SC;
      font-weight: 500;
      color: #333333;
    }
    .solution_button {
      background-color: #fff;
      border: 1px solid #f78900;
      color: rgba(247, 137, 0, 1);
      width: 140px;
      height: 45px;
      border-radius: 4px;
    }
    .solution_button:hover {
      color: #fff;
      background-color: rgba(247, 137, 0, 1);
    }
  }
}
.about {
  background: #fcfcfc;
  .about_content {
    .left {
      width: 100%;
      position: relative;
      box-shadow: 0px 0px 16px 4px rgba(117, 156, 255, 0.1);
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      position: relative;
      .iconfont {
        color: #f78900;
        font-size: 60px;
        position: absolute;
        opacity: 0.8;
        top: -30px;
        right: 150px;
        font-weight: normal;
      }
      > p {
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #666666;
        text-align: left;
        width: 75%;
      }
      .about_title {
        font-family: Source Han Sans SC;
        font-weight: 400;
        color: #333333;
      }
      .border_p {
        width: 60px;
        height: 6px;
        background: #f78900;
        border-radius: 3px;
      }
    }
  }
  .about_bottom {
    height: 220px;
    .item {
      width: calc(100% / 3);
      color: #373840;
      text-align: center;
      > p {
        font-size: 20px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        line-height: 32px;
        > p:nth-of-type(1) {
          font-size: 28px;
          font-family: Adobe Heiti Std;
          font-weight: normal;
          padding-bottom: 32px;
        }
        .iconfont {
          font-size: 34px;
          padding-right: 18px;
        }
      }
    }
  }
}

@media screen and(max-width:1200px) {
  .slot_content {
    top: 150px;
    > p:last-of-type {
      font-size: 16px;
      padding-top: 15px;
    }
    .border_p {
      height: 4px;
      > span {
        width: 580px;
        height: 4px;
      }
    }
    .tips {
      font-size: 50px;
    }
    .company {
      font-size: 30px;
    }
  }
  .top_content {
    height: 180px;
    top: -150px;
    .item {
      > p {
        font-size: 14px;
        padding-top: 12px;
      }
      > img {
        width: 60px;
        height: 60px;
      }
    }
    .bg_top_content {
      height: 180px;
    }
  }
  .title {
    font-size: 25px;
    line-height: 30px;
    height: 60px;
    margin-bottom: 100px;
  }
  .join {
    padding: 100px 0;
    .title {
      margin-bottom: 20px;
    }
    .join_content {
      margin-top: 50px;
      height: 200px;
    }
    // img {
    //   width: 150px;
    //   height: 75px;
    // }
  }
  .solution_content {
    height: 267px;
    margin-bottom: 40px;
    img {
      width: 400px;
      height: 267px;
    }
    > div {
      > p {
        font-size: 14px;
        padding: 15px 0;
      }
      > p:nth-of-type(1) {
        font-size: 22px;
      }
      .solution_button {
        width: 100px;
        height: 35px;
        font-size: 14px;
      }
    }
  }
  .about {
    height: 500px;
    .about_content {
      .left {
        top: -40px;
        img {
          width: 539.3px;
          height: 303px;
        }
      }
      .right {
        padding: 30px 0 0 50px;
        .iconfont {
          font-size: 40px;
          top: -20px;
        }
        > p {
          font-size: 14px;
          line-height: 24px;
        }
        .about_title {
          font-size: 25px;
        }
        .border_p {
          margin: 15px 0 25px 0;
        }
      }
    }
    .about_bottom {
      height: 150px;
      .item {
        > p {
          font-size: 16px;
          line-height: 25px;
        }
        > p:nth-of-type(1) {
          font-size: 20px;
          padding-bottom: 20px;
        }
        .iconfont {
          font-size: 24px;
          padding-right: 10px;
        }
      }
    }
  }
}
@media screen and(min-width:1500.1px) and(max-width:1700px) {
  .slot_content {
    top: 320px;
    > p:last-of-type {
      font-size: 20px;
      padding-top: 20px;
    }
    .border_p {
      height: 4px;
      margin: 35px 0;
      > span {
        width: 580px;
        height: 4px;
      }
    }
    .tips {
      font-size: 72px;
    }
    .company {
      font-size: 48px;
    }
  }
  .top_content {
    height: 248px;
    top: -204px;
    .item {
      > p {
        font-size: 18px;
        padding-top: 20px;
      }
      > img {
        width: 80px;
        height: 80px;
      }
    }
    .bg_top_content {
      height: 248px;
    }
  }
  .join {
    padding: 150px 0;
    .title {
      margin-bottom: 20px;
    }
    .join_content {
      margin-top: 80px;
      height: 270px;
    }
    // img {
    //   width: 190px;
    //   height: 95px;
    // }
  }
  .title {
    margin-bottom: 140px;
  }
  .solution_content {
    height: 395px;
    margin-bottom: 80px;
    img {
      width: 500px;
      height: 333px;
    }
    > div {
      > p {
        font-size: 16px;
        padding: 15px 0;
      }
      > p:nth-of-type(1) {
        font-size: 28px;
      }
    }
  }
  .about {
    height: 702px;
    .about_content {
      height: calc(702px - 220px - 75px);
      .left {
        top: -80px;
        img {
          width: 748px;
          height: 420px;
        }
      }
      .right {
        padding: 70px 0 0 100px;
        > p {
          font-size: 16px;
          line-height: 36px;
        }
        .about_title {
          font-size: 36px;
        }
        .border_p {
          margin: 25px 0 50px 0;
        }
      }
    }
    .about_bottom {
      height: 220px;
      .item {
        > p {
          font-size: 20px;
          line-height: 32px;
        }
        > p:nth-of-type(1) {
          font-size: 28px;
          padding-bottom: 30px;
        }
        .iconfont {
          font-size: 34px;
          padding-right: 18px;
        }
      }
    }
  }
}
@media screen and(min-width:1200.1px) and(max-width:1500px) {
  .slot_content {
    top: 350px;
    > p:last-of-type {
      font-size: 20px;
      padding-top: 20px;
    }
    .border_p {
      height: 4px;
      margin: 35px 0;
      > span {
        width: 580px;
        height: 4px;
      }
    }
    .tips {
      font-size: 72px;
    }
    .company {
      font-size: 48px;
    }
  }
  .top_content {
    height: 248px;
    top: -204px;
    .item {
      > p {
        font-size: 18px;
        padding-top: 20px;
      }
      > img {
        width: 80px;
        height: 80px;
      }
    }
    .bg_top_content {
      height: 248px;
    }
  }
  .join {
    padding: 150px 0;
    .title {
      margin-bottom: 20px;
    }
    .join_content {
      margin-top: 80px;
      height: 240px;
    }
    // img {
    //   width: 170px;
    //   height: 85px;
    // }
  }
  .title {
    margin-bottom: 120px;
  }
  .solution_content {
    height: 360px;
    margin-bottom: 70px;
    img {
      width: 420px;
      height: 280px;
    }
    > div {
      > p {
        font-size: 16px;
        padding: 15px 0;
      }
      > p:nth-of-type(1) {
        font-size: 26px;
      }
      .solution_button {
        width: 120px;
        height: 40px;
      }
    }
  }
  .about {
    height: 650px;
    .about_content {
      height: calc(702px - 220px - 75px);
      .left {
        top: -40px;
        img {
          width: 605px;
          height: 340px;
        }
      }
      .right {
        .iconfont {
          font-size: 50px;
          top: -25px;
        }
        padding: 60px 0 0 85px;
        > p {
          font-size: 16px;
          line-height: 26px;
        }
        .about_title {
          font-size: 32px;
        }
        .border_p {
          margin: 20px 0 30px 0;
        }
      }
    }
    .about_bottom {
      height: 220px;
      .item {
        > p {
          font-size: 18px;
          line-height: 30px;
        }
        > p:nth-of-type(1) {
          font-size: 24px;
          padding-bottom: 27px;
        }
        .iconfont {
          font-size: 32px;
          padding-right: 15px;
        }
      }
    }
  }
}
@media screen and(min-width:1700.1px) {
  .slot_content {
    top: 320px;
    > p:last-of-type {
      font-size: 20px;
      padding-top: 20px;
    }
    .border_p {
      height: 4px;
      margin: 35px 0;
      > span {
        width: 580px;
        height: 4px;
      }
    }
    .tips {
      font-size: 72px;
    }
    .company {
      font-size: 48px;
    }
  }
  .top_content {
    height: 248px;
    top: -204px;
    .item {
      > p {
        font-size: 18px;
        padding-top: 20px;
      }
      > img {
        width: 80px;
        height: 80px;
      }
    }
    .bg_top_content {
      height: 248px;
    }
  }
  .join {
    padding: 150px 0;
    .title {
      margin-bottom: 20px;
    }
    .join_content {
      margin-top: 80px;
      height: 290px;
    }
    // img {
    //   width: 240px;
    //   height: 120px;
    // }
  }
  .title {
    margin-bottom: 140px;
  }
  .solution_content {
    height: 395px;
    margin-bottom: 80px;
    img {
      width: 590px;
      height: 394px;
    }
    > div {
      > p {
        font-size: 16px;
        padding: 15px 0;
      }
      > p:nth-of-type(1) {
        font-size: 28px;
      }
    }
  }
  .about {
    height: 702px;
    .about_content {
      height: calc(702px - 220px - 75px);
      .left {
        top: -80px;
        img {
          width: 860px;
          height: 484px;
        }
      }
      .right {
        padding: 70px 0 0 100px;
        > p {
          font-size: 16px;
          line-height: 36px;
        }
        .about_title {
          font-size: 36px;
        }
        .border_p {
          margin: 25px 0 50px 0;
        }
      }
    }
    .about_bottom {
      height: 220px;
      .item {
        > p {
          font-size: 20px;
          line-height: 32px;
        }
        > p:nth-of-type(1) {
          font-size: 28px;
          padding-bottom: 30px;
        }
        .iconfont {
          font-size: 34px;
          padding-right: 18px;
        }
      }
    }
  }
}
</style>
